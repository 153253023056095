import React, { useContext, useEffect, useState, useCallback } from 'react';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { AccountContext } from '../../Accounts';
import { DataTypeContext } from '../../DataTypes';
import { API, COGNITO_POOL_ID } from '../../Constants';
import { formatDate } from '../../Utils';
import ProfileForm from './ProfileForm';
import ProfileFormReview from './ProfileFormReview';

const ProfileInformation = () => {
    const {
        clientProfileId,
        clientId,
        setClientId,
        email,
        phone,
        dob,
        setLink2FeedClientId,
        firstName,
        lastName,
        setClientProfileId,
        getUserAttributes,
        updateFirstAndLastName,
        consentInfoSigned,
        consentContactEmailSigned,
        consentContactSMSSigned,
        consentContactVoiceSigned,
    } = useContext(AccountContext);

    const { dataTypes } = useContext(DataTypeContext);
    const { useIdWorkflow } = dataTypes;
    const enabledFields = dataTypes.enabledFields || [];
    const requiredFields = dataTypes.requiredFields || [];
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [sessionExpired, setSessionExpired] = useState(false);
    const isPartialProfile =
        (location && location.state !== undefined && location.state.partialProfile) || sessionExpired;
    const isEdit = location && location.state !== undefined && location.state.edit && location.state.edit === true;
    const [isLoading, setLoading] = useState(false);
    const [isReview, setIsReview] = useState(false);
    const [formData, setFormData] = useState(null);

    // ...(enabledFields.includes('') && {  })
    const schema = Yup.object({
        ...(isEdit && { firstName: Yup.string().required('First Name is required') }),
        ...(isEdit && { lastName: Yup.string().required('Last Name is required') }),
        ...((isPartialProfile || isEdit) && { dob: Yup.date().max(new Date()).label('Date of Birth').required() }),
        noFixedAddress: Yup.boolean(),
        addressDeclinedToAnswer: Yup.boolean(),
        addressLine1: Yup.string()
            .label('Address Line 1')
            .when(['noFixedAddress', 'addressDeclinedToAnswer'], { is: false, then: Yup.string().required() }),
        ...(enabledFields.includes('addressLine2') && { addressLine2: Yup.string() }),
        city: Yup.string()
            .label('City')
            .when(['noFixedAddress', 'addressDeclinedToAnswer'], { is: false, then: Yup.string().required() }),
        ...(enabledFields.includes('county') && {
            county: Yup.string()
                .label('County')
                .when(['noFixedAddress', 'addressDeclinedToAnswer'], { is: false, then: Yup.string().required() }),
        }),
        state: Yup.string()
            .label(t('address.state'))
            .when(['noFixedAddress', 'addressDeclinedToAnswer'], { is: false, then: Yup.string().required() }),
        zip: Yup.string()
            .label(t('address.zipcode'))
            .when(['noFixedAddress', 'addressDeclinedToAnswer'], { is: false, then: Yup.string().required() }),
        ...(enabledFields.includes('gender') && { gender: Yup.string().required('Gender is required') }),
        ...(requiredFields.includes('employmentType') && enabledFields.includes('employmentType') && {
            employmentType: Yup.string().required('Employment Type is required'),
        }),
        ...(requiredFields.includes('housingType') && enabledFields.includes('housingType') && { housingType: Yup.string().label('Housing Type').required() }),
        ...(requiredFields.includes('educationType') && enabledFields.includes('educationType') && {
            educationType: Yup.string().required('Highest Education Level Completed is required'),
        }),
        ...(requiredFields.includes('maritalStatus') && enabledFields.includes('maritalStatus') && {
            maritalStatus: Yup.string().label('Marital Status').required(),
        }),
        ...(requiredFields.includes('selfIdentificationType') && enabledFields.includes('selfIdentificationType') && {
            selfIdentificationType: Yup.array().of(Yup.string()).label('Self-Identifies As').min(1).required(),
        }),
        ...(requiredFields.includes('dietaryConsiderationTypes') && enabledFields.includes('dietaryConsiderationTypes') && {
            dietaryConsiderationTypes: Yup.array().of(Yup.string()).label('Dietary Considerations').min(1).required(),
        }),
        ...(requiredFields.includes('socialProgramTypes') && enabledFields.includes('socialProgramTypes') && {
            socialProgramTypes: Yup.array().of(Yup.string()).label('Social Programs').min(1).required(),
        }),
        ...(requiredFields.includes('languageTypes') && enabledFields.includes('languageTypes') && {
            languageTypes: Yup.array().of(Yup.string()).label('Languages').min(1).required(),
        }),
        ...(requiredFields.includes('ethnicity') && enabledFields.includes('ethnicity') && {
            ethnicityType: Yup.array().of(Yup.string()).label(t('intake.ethnicities')).min(1).required(),
        }),
        ...(requiredFields.includes('isHispanicLatino') && enabledFields.includes('isHispanicLatino') && {
            isHispanicLatino: Yup.string().label(t('intake.isHispanicLatino')).required(),
        }),
        ...(requiredFields.includes('incomeType') && enabledFields.includes('incomeType') && { incomeType: Yup.string().required('Income Type is required') }),
        ...(requiredFields.includes('income') && enabledFields.includes('income') && {
            incomeAmount: Yup.number().min(0).max(999999).label('Monthly Amount').required(),
        }),
        ...(!requiredFields.includes('income') && enabledFields.includes('income') && {
            incomeAmount: Yup.number().min(0).max(999999).label('Monthly Amount'),
        }),
        ...(requiredFields.includes('referredBy') && enabledFields.includes('referredBy') && { referredBy: Yup.string().label('Referred By').required() }),
        hhMembers: Yup.array().of(
            Yup.object().shape({
                firstName: Yup.string().label('First Name').required(),
                lastName: Yup.string().label('Last Name').required(),
                dob: Yup.date().label('Date of Birth').max(new Date()).required(),
                ...(enabledFields.includes('gender') && { hhmGender: Yup.string().label('Gender').required() }),
                ...(enabledFields.includes('relationship') && {
                    hhmRelationship: Yup.string().label('Relationship').required(),
                }),
                hhmEstimatedDOB: Yup.boolean(),
            })
        ),
        ...(requiredFields.includes('expenseTypes') && enabledFields.includes('expenseTypes') && {
            expenseTypes: Yup.array().when('expenseCount', {
                is: (val) => val > 0,
                then: Yup.array()
                    .of(
                        Yup.object().shape({
                            type: Yup.string().label('Type').required(),
                            amount: Yup.number().min(0).max(999999).label('Amount').required(),
                        })
                    )
                    .required(),
            })
        }),
    });

    const [initialValues, setInitialValues] = useState({
        hhMembers: [],
        expenseTypes: [],
        expenseCount: 0,
        noFixedAddress: false,
        addressDeclinedToAnswer: false,
        ...(enabledFields.includes('income') && { incomeAmount: 0 }),
    });

    const [clientLoading, setClientLoading] = useState(isEdit ? true : false);

    const findClient = async (dob) => {
        if (isPartialProfile) {
            const findToken = await executeRecaptcha('details');
            const findPayload = { token: findToken, firstName: firstName, lastName: lastName, dob: formatDate(dob) };
            try {
                const findResponse = await (
                    await fetch(API.FIND_CLIENT_URL, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(findPayload),
                    })
                ).json();

                if (findResponse && findResponse.clientProfileId) {
                    setClientProfileId(findResponse.clientProfileId);

                    if (findResponse.link2feedId !== undefined) {
                        setClientId(findResponse.link2feedId);
                        setLink2FeedClientId((findResponse.link2feedId || '').toString());

                        history.push('/thank-you');
                    }
                }
            } catch (e) {
                alert('/find exception');
            }
        }
    };

    const sendDataToAPI = async () => {
        const data = formData;
        if (!isLoading) {
            setLoading(true);
            const token = await executeRecaptcha('details');
            let payload = {
                token: token,
                clientProfileId: clientProfileId,
                ...(isEdit && { firstName: data.firstName }),
                ...(isEdit && { lastName: data.lastName }),
                ...((isEdit || (!isEdit && useIdWorkflow)) && { dateOfBirth: formatDate(data.dob || dob) }),
                addressLine1: data.addressLine1,
                ...(enabledFields.includes('addressLine2') && { addressLine2: data.addressLine2 }),
                city: data.city,
                ...(enabledFields.includes('county') && { county: data.county }),
                state: data.state,
                zipCode: data.zip,
                latitude: data.latitude,
                longitude: data.longitude,
                noFixedAddress: data.noFixedAddress
                    ? 'noFixedAddress'
                    : '' || data.addressDeclinedToAnswer
                    ? 'addressDeclinedToAnswer'
                    : '',
                ...(enabledFields.includes('gender') && { gender: data.gender }),
                ...(enabledFields.includes('income') && { income: data.incomeAmount }),
                ...(enabledFields.includes('incomeType') && { incomeType: data.incomeType }),
                ...(enabledFields.includes('housingType') && { housingType: data.housingType }),
                ...(enabledFields.includes('selfIdentificationType') && {
                    selfIdentificationType: data.selfIdentificationType,
                }),
                ...(enabledFields.includes('educationType') && { educationType: data.educationType }),
                ...(enabledFields.includes('employmentType') && { employmentType: data.employmentType }),
                ...(enabledFields.includes('ethnicity') && { ethnicity: data.ethnicityType }),
                ...(enabledFields.includes('isHispanicLatino') && { isHispanicLatino: data.isHispanicLatino }),
                ...(enabledFields.includes('maritalStatus') && { maritalStatus: data.maritalStatus }),
                shareConsentDate: consentInfoSigned,
                textConsentDate: consentContactSMSSigned,
                emailConsentDate: consentContactEmailSigned,
                voiceConsentDate: consentContactVoiceSigned,
                householdMembers: [],
                ...(enabledFields.includes('otherIncomes') && { otherIncomes: {} }),
                ...(enabledFields.includes('expenseTypes') && { expenseTypes: {} }),
                ...(enabledFields.includes('referredBy') && { referredBy: data.referredBy }),
                ...(enabledFields.includes('socialProgramTypes') && { socialProgramTypes: data.socialProgramTypes }),
                ...(enabledFields.includes('dietaryConsiderationTypes') && {
                    dietaryConsiderationTypes: data.dietaryConsiderationTypes,
                }),
                ...(enabledFields.includes('languageTypes') && { languageTypes: data.languageTypes ?? [] }),
                email: email,
                phoneNumber: phone,
                validationMode: 'case_mgmt',
            };

            data.hhMembers.forEach(function (member, index) {
                payload.householdMembers.push({
                    firstName: member.firstName,
                    lastName: member.lastName,
                    dateOfBirth: formatDate(member.dob),
                    ...(enabledFields.includes('gender') && { gender: member.hhmGender }),
                    ...(enabledFields.includes('relationship') && { relationship: member.hhmRelationship }),
                    dobEstimated: member.hhmEstimatedDOB,
                    clientProfileId: member.clientProfileId,
                });
            });

            if (
                enabledFields.includes('otherIncomes') &&
                data.otherIncomes !== undefined &&
                data.otherIncomes.length > 0
            ) {
                data.otherIncomes.forEach(function (type, index) {
                    payload.otherIncomes[type] = 0;
                });
            }

            if (enabledFields.includes('expenseTypes') &&
                data.expenseTypes.length > 0
            ) {
                data.expenseTypes.forEach(function (expense, index) {
                    payload.expenseTypes[expense.type] = expense.amount;
                });
            }

            await fetch(API.NEW_CLIENT_URL, {
                method: isEdit ? 'PATCH' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then((response) =>
                    response.json().then(async (data) => {
                        if (response.ok && data.link2feedId !== undefined) {
                            setClientId(data.link2feedId);
                            setLink2FeedClientId((data.link2feedId || '').toString());

                            const attributes = await getUserAttributes();

                            const body = {
                                id: attributes.sub,
                                pool_id: COGNITO_POOL_ID,
                            };

                            const url = new URL(API.CNCT_ADMIN_REFETCH_USER_URL);
                            url.search = new URLSearchParams(body).toString();

                            fetch(url, { mode: 'no-cors' });

                            history.push('/thank-you');
                        } else if (data.message !== undefined || data.error !== undefined) {
                            // if session expired, enable partial profile to confirm DOB and get another session
                            const dataError = data.message || data.error;
                            if (dataError === t('session.apiSessionNotFound')) {
                                setSessionExpired(true);
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            } else alert(dataError);
                            setLoading(false);
                        } else {
                            alert('client not created');
                        }
                    })
                )
                .catch((error) => {
                    alert('/clients exception');
                });
        }
    };

    const fetchClientDataAndPopulate = useCallback(async () => {
        const token = await executeRecaptcha('details');
        const res = await fetch(`${API.CLIENT_DETAILS_URL}/${clientId}?token=${token}`);
        const clientData = await res.json();

        if (clientData && clientData.clientProfileId) {
            updateFirstAndLastName(clientData.firstName, clientData.lastName);
            const initialValues = {};
            initialValues.firstName = clientData.firstName;
            initialValues.lastName = clientData.lastName;
            initialValues.dob = clientData.dateOfBirth
                ? moment(clientData.dateOfBirth, dataTypes.dateFormat.toUpperCase()).toDate()
                : '';
            initialValues.gender = clientData.gender;

            // address
            initialValues.addressLine1 = clientData.addressLine1;
            initialValues.addressLine2 = clientData.addressLine2 ? clientData.addressLine2 : '';
            initialValues.city = clientData.city;
            initialValues.county = clientData.county;
            initialValues.state = clientData.state;
            initialValues.zip = clientData.zipCode;
            initialValues.noFixedAddress = clientData.noFixedAddress === 'noFixedAddress' || clientData.noFixedAddress === true;
            initialValues.addressDeclinedToAnswer = clientData.noFixedAddress === 'addressDeclinedToAnswer';

            // selects
            initialValues.employmentType = clientData.employmentType;
            initialValues.housingType = clientData.housingType;
            initialValues.educationType = clientData.educationType;
            initialValues.maritalStatus = clientData.maritalStatus;
            initialValues.referredBy = clientData.referredBy;
            initialValues.languageTypes = clientData.languageTypes;

            // checkboxes
            initialValues.selfIdentificationType = clientData.selfIdentificationType;
            initialValues.ethnicityType = clientData.ethnicity;
            initialValues.dietaryConsiderationTypes = clientData.dietaryConsiderationTypes;
            initialValues.socialProgramTypes = clientData.socialProgramTypes;
            initialValues.otherIncomes = Object.keys(clientData.otherIncomes);

            initialValues.incomeType = clientData.incomeType;
            initialValues.incomeAmount = clientData.income;

            // radios
            initialValues.isHispanicLatino = {true: "yes", false: "no"}[clientData.isHispanicLatino];

            // hhm
            initialValues.hhMembers = [];
            if (clientData.householdMembers.length > 0) {
                initialValues.hhMembers = clientData.householdMembers.map((hhm) => {
                    const obj = {};
                    obj.firstName = hhm.firstName;
                    obj.lastName = hhm.lastName;
                    obj.dob = hhm.dateOfBirth
                        ? moment(hhm.dateOfBirth, dataTypes.dateFormat.toUpperCase()).toDate()
                        : '';
                    obj.hhmEstimatedDOB = hhm.dobEstimated;
                    obj.hhmGender = hhm.gender;
                    obj.hhmRelationship = hhm.relationship;
                    obj.clientProfileId = hhm.clientProfileId;
                    return obj;
                });
            }

            // expenses
            initialValues.expenseCount = clientData.expenseTypes.length;
            initialValues.expenseTypes = [];
            for (const [key, value] of Object.entries(clientData.expenseTypes)) {
                initialValues.expenseTypes.push({ type: key, amount: value });
            }

            setInitialValues(initialValues);
            setClientProfileId(clientData.clientProfileId);
            // hide loader
            setClientLoading(false);
        } else {
            alert('Something went wrong. Please try again.');
        }
    }, [clientId, dataTypes, setClientProfileId, executeRecaptcha, updateFirstAndLastName]);

    useEffect(() => {
        if (isEdit) fetchClientDataAndPopulate();
    }, [isEdit, fetchClientDataAndPopulate]);

    return isReview ? (
        <ProfileFormReview
            onSubmit={sendDataToAPI}
            onCancel={() => {
                setIsReview(false);
                setInitialValues(formData);
            }}
            formData={formData}
            isLoading={isLoading}
        />
    ) : (
        <ProfileForm
            schema={schema}
            onSubmit={(data) => {
                setFormData(data);
                setIsReview(true);
            }}
            initialValues={initialValues}
            dataTypes={dataTypes}
            isLoading={isLoading}
            isSessionExpired={sessionExpired}
            clientLoading={clientLoading}
            findClient={findClient}
        />
    );
};

export default ProfileInformation;
